<template>
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'mainPage',
    mounted() {
        this.getCustomInfo()
    },
    methods: {
        ...mapActions(['getCustomInfo'])
    }
}
</script>

<style>

</style>