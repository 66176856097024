import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/views/main/index.vue";

import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式
const baseRouter = [
  {
    path: "/",
    name: "main",
    component: MainPage,
    children: [
      {
        path: "/home",
        component:  () => import("@/views/home/index.vue"),
      },
      {
        path: "/02M02T02R02J018",
        component:  () => import("@/views/home1/index.vue"),
      },
      {
        path: "/02M02T02R02J019",
        component:  () => import("@/views/home2/index.vue"),
      },
      {
        path: "/02M02T02R02J01A",
        component:  () => import("@/views/home3/index.vue"),
      },
      {
        path: "/02M02T02R02J01B",
        component:  () => import("@/views/home4/index.vue"),
      },
      {
        path: "/404",
        component:  () => import("@/views/404/index.vue"),
      },
    ]
  },
  
];
const router = createRouter({
  history: createWebHistory(),
  routes: [...baseRouter],
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // 允许进入登录页面  1005 需要完善信息 不能清除登录信息
  next();
  NProgress.done();
  return;
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
export default router;
