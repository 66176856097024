// store.js
import titlelog from '@/assets/base/logo.png';
import account from '@/assets/base/account.png';
import afterSales from '@/assets/base/afterSales.png';
import preSales from '@/assets/base/preSales.png';
import {createStore} from 'vuex';
import router from './../router/index'
import axios from 'axios';

const baseInfo = {
    titlelog,
    webname: "CPS分账系统",
    account,
    webphone: "18884860086",
    preSales,
    afterSales,
    home: "",
    publicSecurityFiling: "川公网安备51012202001705",
    recordNumber: "Copyright 2024 SUMOU All Rights Reserved 蜀ICP备2023042080号-1"
}
export default createStore({
    state() {
        return {
            baseUrl: 'https://cpsserver.sumou.fun', titlelog: '',  //logo 图片
            webname: '',   // 网站名字
            webphone: '',  // 联系人
            preSales: '', //售前微信图片
            afterSales: '', //售后微信
            account: '', //售后微信
            recordNumber: '',  //工信部备案号
            publicSecurityFiling: '',  //公安备案号
            home: '',
        };
    }, mutations: {
        setInfos(state, data) {
            state.titlelog = data.titlelogUrl
            state.webname = data.webname || baseInfo.webname
            state.webphone = data.webphone || baseInfo.webphone
            state.preSales = data.preSalesUrl
            state.afterSales = data.afterSalesUrl
            state.account = data.accountUrl
            state.recordNumber = data.recordNumber || baseInfo.recordNumber
            state.publicSecurityFiling = data.publicSecurityFiling || baseInfo.publicSecurityFiling
            state.home = data.home || baseInfo.home
        }
    }, actions: {
        async getCustomInfo({state, commit}) {
            // const location = window.location
            // axios.get(`${state.baseUrl}/setting/getserversetting?domain=oem.sumou.fun`)
            axios.get(`${state.baseUrl}/setting/getserversetting?domain=${location.host}`)
                .then(async (res) => {
                    if (res.data) {
                        const {afterSales, preSales, account, publicSecurityFiling, recordNumber, titlelog, webname, webphone, home} = res.data;
                        const afterSalesUrl = afterSales ? `https://file.sumou.fun/${afterSales}` : baseInfo.afterSales
                        const preSalesUrl = preSales ? `https://file.sumou.fun/${preSales}` : baseInfo.preSales
                        const accountUrl = account ? `https://file.sumou.fun/${account}` : baseInfo.account
                        const titlelogUrl = titlelog ? `https://file.sumou.fun/${titlelog}` : baseInfo.titlelog
                        document.title = webname
                        commit('setInfos', {
                            home, afterSalesUrl, preSalesUrl, accountUrl, publicSecurityFiling, recordNumber, titlelogUrl, webname, webphone
                        })
                        router.push(home ? `/${home}` : '/home')
                    } else {
                        commit('setInfos', {})
                        router.push('/home')
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, getters: {}
});

