<template>
  <transition name="fade-transform" mode="out-in">
    <component :is="layout">
      <router-view v-if="isRouterAlive" />
    </component>
  </transition>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  name: "App",
  components: {},
  data() {
    return {
      defaultLayout: "main-layout",
      isRouterAlive: true,
    };
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout;
      return layout ? `${layout}-layout` : this.defaultLayout;
    },
  },
  created() {
    this.getCustomInfo()
  },
  methods: {
    ...mapActions(['getCustomInfo']),

  },
};
</script>

<style>
@import url(./assets/style/basic.less);
</style>
